import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import { colors, util, mq } from 'src/styles'
import { Transition } from 'react-transition-group'

const timeout = 300
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;

	${ ({ position }) => {
		if (position?.includes('top')) {
			return `align-items: flex-start;`
		} else if (position?.includes('bottom')) {
			return `align-items: flex-end;`
		}
	} }

	${ ({ position }) => {
		if (position?.includes('left')) {
			return `justify-content: flex-start;`
		} else if (position?.includes('right')) {
			return `justify-content: flex-end;`
		}
	} }

	//${ ({ padded }) => util.responsiveStyles('padding', 60, 50, 30, 14) }
	transition: opacity ${ timeout }ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
	// transform: translate3d(110%, 0, 0);
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
		// transform: translate3d(110%, 0, 0);
		opacity: 0;
	` : `` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		// transform: none;
		opacity: 1;
	` : `` }
`

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${ colors.textColor };
	opacity: .3;
	z-index: 1;
`

const ModalBox = styled.div`
	background: ${ colors.bgColor };
	${ ({ padded }) => padded ? `
		${ util.responsiveStyles('padding', 80, 60, 40, 20) }
	` : `` }
	position: relative;
	z-index: 2;
`

const CloseButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
`

const Modal = ({
	children,
	className,
	open,
	padded = true,
	overlayCloseable = true,
	onClose,
	position
}) => {
	const handleClose = event => {
		if (onClose) {
			onClose(event)
		}
	}

	return (
		<Transition
      in={open}
      timeout={{
        enter: 1,
        exit: timeout
      }}
      unmountOnExit
      mountOnEnter
    >
      {transitionStatus => (
				<ModalWrapper
					setTheme="lightGrey"
					className={className}
					transitionStatus={transitionStatus}
					position={position}
				>
					<Overlay onClick={event => overlayCloseable ? handleClose(event) : false}/>
					<ModalBox
						padded={padded}
						className="modal-box"
					>
						{children}
						<CloseButton icon='close' shape='circle' onClick={event => handleClose(event)} size='small' setTheme='transparent' />
					</ModalBox>
				</ModalWrapper>
			)}
    </Transition>
	)
}

export default Modal
